import React, {} from 'react'
import Layout from '../components/layout';
import ContactBlock from '../components/contact-block'
import {Helmet} from "react-helmet"
import {graphql, useStaticQuery} from "gatsby";

export default () => {
    const contactUsData = useStaticQuery(graphql`
        {
	        contactBlock: wpPage(slug: {eq: "contacts"}) {
	            title
                contactUsCustomFields {
                    contactUsBlock {
                        description
                        text
                        titleH2
                        titleH4
                    }
                }
                seo {
                    title
                    metaDesc
                    canonical
                }
                additionalMetaTag {
                    keywords
                    ogDescription
                    ogTitle
                    ogType
                    ogImage {
                        mediaItemUrl
                    }
                    twitterImage{
                        mediaItemUrl
                    }
                    twitterTitle
                    twitterDescription
                }
            }
        }
	`)
    const {contactBlock} = contactUsData;
    const {contactUsCustomFields, seo, additionalMetaTag, title} = contactBlock;
    const data = contactUsCustomFields?.contactUsBlock;
    // Set metadata
    const seoData = {...{seo}, additionalMetaTag, title};

    return (
        <Layout
            seoData={seoData}>
            <div property={'disablePreFooter'}
                 className={'contacts'}>
                <Helmet
                    bodyAttributes={{
                        class: 'dark-background main-without-margin'
                    }}
                />
                <div className="page-content-modal page-content">
                    {data && <ContactBlock
                        parentBlock={'contact-us'}
                        captchaRender={true}
                        contactBlock={data}/>}
                </div>
            </div>
        </Layout>
    )
}




